@mixin borderRadius($q) {
  -webkit-border-radius: $q;
  -moz-border-radius: $q;
  border-radius: $q;
}

@mixin transition($property: all, $duration: 300ms, $timing-function: ease-in-out, $delay: 0ms) {
  -webkit-transition: $property $duration $timing-function $delay;
  -moz-transition: $property $duration $timing-function $delay;
  -ms-transition: $property $duration $timing-function $delay;
  -o-transition: $property $duration $timing-function $delay;
  transition: $property $duration $timing-function $delay;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin focusable {
  @at-root {
    &:hover {
      cursor: pointer;

      @content;
    }
    &.focus {
      @content;
    }
  }
}
@mixin box-shadow($properties...) {
  -webkit-box-shadow: $properties;
  -moz-box-shadow: $properties;
  box-shadow: $properties;
}

@mixin animation($properties...) {
  -webkit-animation: $properties;
  -moz-animation: $properties;
  -ms-animation: $properties;
  -o-animation: $properties;
  animation: $properties;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

$keySize: v(56px);
@function biggerKey($n, $margin, $key: $keySize) {
  @return h(($n * $key) + ($margin * 2 * ($n - 1)));
}
@mixin multiplyKey($margin) {
  $i: 0;
  @for $i from 1 through 3 {
    &.multiply-#{$i} {
      width: biggerKey($i, $margin);
    }
    $i: $i + 1;
  }
}

%regularKey {
  width: h($keySize);
  height: v($keySize);
  font-size: toRem(48);
  @extend %font-regular;
  text-align: center;
  display: inline-block;
  margin: v(12.5px) h(12.5px);
  line-height: toRem(80);
  color: rgba(255, 255, 255, 0.75);
  &.focus {
    color: #140e2a;
  }
}
