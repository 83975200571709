@import './reset';
@import './themes';

@include font-face();

#app {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain;
  &.nob-theme {
    background-image: url('./../images/backgrounds/#{$first-bg}');

    .appDetailsView #waveBack {
      background-image: url('../../assets/images/backgrounds/background-store-detail.png');
    }
  }
  &.default-theme {
    background-image: url('./../images/backgrounds/#{$second-bg}');

    #Header #branding {
      #logo {
        background-image: url('../../assets/images/logo-default.png');
      }
      #tvStore {
        background: none;
      }
    }

    .appDetailsView #waveBack {
      background-image: url('../../assets/images/backgrounds/background-store-detail-default.png');
    }
  }
  &.turk-theme {
    background-image: url('./../images/backgrounds/#{$third-bg}');

    .tosView {
      color: #1a1a1a;
    }

    #portalFilter p {
      color: #1a1a1a;
    }
    #portalFilter .fa-check {
      color: #140e2a;
    }
    .welcome {
      color: #1a1a1a;
    }
    #Header {
      .headerPart {
        bottom: v(25px);
      }
      #menu {
        .optionMenu {
          background-color: rgba(21, 58, 100, 0.5);
          &.focus {
            box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          }
          .text {
            font-weight: 500;
            font-size: toRem(32);
          }
        }
      }
      #branding {
        #logo {
          background-image: url('../../assets/images/logo-turk.png');
          width: h(308px);
          height: v(100px);
        }
        #tvStore {
          background: none;
        }
      }
    }

    .carousel .wrapper .slide .image {
      border-radius: h(8px);
    }
    .carousel .wrapper .focus .carouselShine {
      border-radius: h(8px);
    }

    .homeView {
      #points {
        .point {
          background-color: rgba(21, 58, 100, 0.35);
          &.selected {
            background-color: #153a64;
          }
        }
      }
      .carouselTitle,
      .fixedTitle,
      .fixedExtended {
        color: #1a1a1a;
      }

      #homeContainer {
        .focus .app .bg .shining {
          border-radius: h(8px);
        }
        .app .bg {
          border-radius: h(8px);
        }
        &.carousel_1 {
          top: h(-445px);
        }
        &.carousel_5 {
          top: h(-2350px);
        }
        #carouselTvCategoriesWrapper {
          .title {
            font-weight: 500;
          }
          .focus .title {
            font-weight: 600;
          }
        }
        #categoriesCarousel {
          .app {
            .title {
              font-size: v(50px);
              font-weight: 500;
            }
          }
          .focus {
            .bg {
              background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
                linear-gradient(119deg, #395196, #5eb7c7 102%);
            }
            .title {
              font-size: v(60px);
              font-weight: 700;
            }
            .title.multiLine {
              font-size: v(50px);
              top: v(110px);
            }
          }
        }
      }
    }
    #search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #1a1a1a;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix .fa-backspace {
        visibility: hidden;
      }
      .keyMatrix .backspace {
        background-image: url('../images/keyboard/backspace.png');
        background-repeat: no-repeat;
        background-position: center;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(60px);
      }
      .keyMatrix .space {
        background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }

    .settings {
      #menuSettings .settingsOption.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
      .switch .filling {
        background-color: #75cdd3;
      }
      #portalFilter .fa-check {
        color: #140e2a;
      }
      #languagesFilter p,
      #portalFilter .matrixSlide p,
      #tagLine,
      #settingsHeader,
      #menuSettings .settingsOption,
      #resetPortal .text {
        color: #1a1a1a;
      }
      #resetPortal .button {
        background-color: rgba(21, 58, 100, 0.5);
        &.focus {
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(111deg, #6385e4, #c86dd7);
        }
      }
      #resetPortal .button p,
      #languagesFilter p {
        font-weight: 500;
        font-size: toRem(32);
      }
    }

    .appDetailsView {
      .button {
        background-color: rgba(21, 58, 100, 0.5);
        color: #ffffff;
        .text {
          font-weight: 500;
          font-size: toRem(32);
          top: h(-14px);
          left: v(-2px);
        }
        &.focus {
          color: #1a1a1a;
          background-color: #ffffff;
        }
      }
      #addToMyApps {
        display: none;
      }
      .appLogo {
        border-radius: h(16px);
      }
      #waveBack {
        background-image: url('../../assets/images/backgrounds/background-turk-detail.png');
      }
      .appData,
      .carouselTitle {
        color: #1a1a1a;
      }
      .languages {
        top: v(108px);
      }
      .carouselTitle {
        font-size: toRem(40);
      }
    }
    .appCategory {
      .categoryTitle,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg img {
          border-radius: h(8px);
        }
      }
    }
    .moviesCategory {
      .title,
      .title_extended,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
    .videoCategory {
      .viewCategory,
      .title_extended,
      .viewTitle {
        color: #1a1a1a;
      }
      .app {
        .title {
          color: #ffffff;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
  }
  &.cultraview-theme {

    background-color: #40365F;
    font-family: $font-family;

    .tosView {
      left: 0;
      top: 0;
      color: #ffffff;

      .welcome {
        left: 0;
        top: v(-100px);
        #welcomeMessage {
          left: 0;
        }
        .pleaseMessage {
          left: 0;
        }
      }

      .portalLanguage {
        top: v(150px);
        left: h(250px);

        #portalFilter {
          left: 0;
        }
      }



      #tosText {
        position: absolute;
        left: h(300px);
        top: v(-100px);
        #checkContainer {
          border-radius: h(16px);
          color: #ffffff;
          &.focus {
            background-color: #ffffff;
            color: #000000;

            .checkbox {
              border: h(3px) solid #000000;
            }
          }
        }
        #tos_accept {
          border: h(2px) solid #ffffff;
          border-radius: h(16px);
          background-color: transparent;

          &.focus {
            background: #ffffff;
            color: #000000;
          }
        }
      }
    }
    #portalFilter {
      .focus {
        .fa-check {
          color: #000000;
        }
        p {
          color: #1a1a1a;
        }
      }
      .fa-check {
        margin-top: v(21px);
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
    }

    #portalFilter {
      p {
        color: #ffffff;
      }
      .focus {
        p {
          color: #1a1a1a;
        }
      }
    }
    #portalFilter .fa-check {
      color: #ffffff;
    }
    .welcome {
      color: #ffffff;
    }
    #Header {
      position: absolute;
      left: 0;
      top: 0;
      height: v(180px);

      -webkit-backdrop-filter: blur(0px);
      backdrop-filter: blur(0px);
      box-shadow: 0 v(2px) h(20px) 0 rgba(0, 0, 0, 0);
      background-color: rgba(10, 7, 14, 0);

      @include transition(all, 200ms, ease-out, 0ms);

      &.overlay {
        -webkit-backdrop-filter: blur(21px);
        backdrop-filter: blur(21px);
        box-shadow: 0 v(2px) h(20px) 0 rgba(0, 0, 0, 0.5);
        background-color: rgba(10, 7, 14, 0.7);
       // background-image: url('../images/backgrounds/background-cultraview-menu.png');
      }

      #menu {
        position: absolute;
        left: 0;
        top: 0;
        #searchButton {
          left: h(615px);
          width: h(690px);

          .search-icon {
            position: absolute;
            left: h(254px);
            top: v(15px);
            width: h(37px);
            height: v(39px);
          }
          .text--search {
            position: absolute;
            left: h(306px);
            top: v(8px);
            margin: unset;
          }
        }
        .optionMenu {
          position: absolute;
          top: v(60px);
          height: v(60px);

          background-color: transparent;
          border: h(3px) solid #ffffff;
          border-radius: h(30px);
          &.focus {
            background-color: #ffffff;
            background-image: none;
            box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.5);
            .text{
              color: #000000;
            }
          }
          .text {
            font-size: toRem(35);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;

          }
        }
      }
      #branding {
        position: absolute;
        left: h(90px);
        top: v(60px);
        #logo {
          background-image: url('../../assets/images/app-store-icon.png');
          width: h(61px);
          height: v(48px);
        }
        #tvStore {
          background: none;
          font-size: toRem(48);
          color: #ffffff;
          @extend %font-regular;
          white-space: nowrap;
        }
      }
    }

    .view {
      background-image: url('../images/backgrounds/background-cultraview.png');

      &.appDetailsView {
       //  background-image: url('../images/backgrounds/background-cultraview-detail.png');

        .backgroundOverlay {
          display: none;
        }

        #waveBack {
          background-image: unset;
        }
      }
    }

    .homeView {
      margin: unset;

      #points {
        .point {
          background-color: rgba(255, 255, 255, 0.35);
          &.selected {
            background-color: #ffffff;
          }
        }
      }

      #homeContainer {
        top: v(198px);
        @include transition(all, 200ms, ease-out, 0ms);

        &.carousel_1 {
          top: h(-84px);
        }
        &.carousel_2 {
          top: h(-245px);
        }

        .fixedTitle {
          position: absolute;
          top: v(495px);
        }

        .carouselTitle,
        .fixedTitle,
        .fixedExtended {
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
        }

        .fixedTitle {
          font-size: toRem(55);
          font-weight: 500;
        }

        .carouselTitle {
          font-size: toRem(45);
          font-weight: normal;
        }

        .carousel {
          opacity: 1;

          .wrapper {
            @include transition(all, 200ms, ease-out, 0ms);
          }
        }

        .focus .app .bg .shining {
          border-radius: h(8px);
        }
        .app .bg {
          border-radius: h(8px);
        }

        #carouselTvCategoriesWrapper {
          .title {
            font-weight: 500;
          }
          .focus .title {
            font-weight: 600;
          }
        }
        #categoriesCarousel {
          .app {
            .title {
              font-size: v(50px);
              font-weight: 500;
            }
          }
          .focus {
            .bg {
              background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
              linear-gradient(119deg, #395196, #5eb7c7 102%);
            }
            .title {
              font-size: v(60px);
              font-weight: 700;
            }
            .title.multiLine {
              font-size: v(50px);
              top: v(110px);
            }
          }
        }
      }
    }
    #search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #ffffff;
      }
      .key.focus {
        color: #1a1a1a;
      }
      .backKey {
        background: #b3f0eb;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(70px);
      }
      .keyMatrix .space {
        //  background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        // background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }

    .settings {
      #menuSettings .settingsOption.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
      #menuSettings .settingsOption.focus {
        background-color: #b3f0eb;
        background-image: none;
      }
      .switch .filling {
        background-color: #3ae1ab;
      }
      #portalFilter .fa-check {
        color: #140e2a;
      }
      .matrixSlide.focus .option > div {
        background-color: #b3f0eb;
      }
      .settingsOption.focus p,
      .matrixSlide.focus > div > div > p {
        color: #1a1a1a !important;
      }
      #languagesFilter p,
      #portalFilter .matrixSlide p,
      #tagLine,
      #settingsHeader,
      #menuSettings .settingsOption,
      #resetPortal .text {
        color: #ffffff;
      }
      #resetPortal .button {
        background-color: #588084;
        border-radius: h(25px);
        &.focus {
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          background-color: #b3f0eb;
          background-image: none;
        }
      }
      #resetPortal .button p,
      #languagesFilter p {
        font-weight: 500;
        font-size: toRem(32);
      }
    }

    .appDetailsView {
      .button {
        background-color: rgba(21, 58, 100, 0.5);
        color: #ffffff;
        .text {
          font-weight: 500;
          font-size: toRem(32);
          top: h(-14px);
          left: v(-2px);
        }
        &.focus {
          color: #1a1a1a;
          background-color: #ffffff;
        }
      }
      #addToMyApps {
        // display: none;
      }

      .appData,
      .carouselTitle {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
      }
      .appData {
        font-size: toRem(65);
        font-weight: 500;
      }
      .carouselTitle {
        font-size: toRem(35);
        font-weight: normal;
      }
      .languages {
        top: v(108px);
      }
      .carouselTitle {
        font-size: toRem(40);
      }
    }
    .appCategory {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      padding-top: v(150px);

      background-image: url('../images/backgrounds/background-cultraview.png');

      .navMatrix {
        top: v(201px);
      }
    }
    .moviesCategory {
      .title,
      .title_extended,
      .viewTitle {
        color: #ffffff;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
    .videoCategory {
      .viewCategory,
      .title_extended,
      .viewTitle {
        color: #ffffff;
      }
      .app {
        .title {
          color: #ffffff;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
  }
  &.boe-theme {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      linear-gradient(119deg, #76c1cf, #b5d6e8 54%, #6b95af);

    .tosView {
      color: #1a1a1a;
    }

    #portalFilter p {
      color: #1a1a1a;
    }
    #portalFilter .fa-check {
      color: #ffffff;
    }
    .welcome {
      color: #ffffff;
    }
    #Header {
      .headerPart {
        bottom: v(25px);
      }
      #menu {
        .optionMenu {
          background-color: #588084;
          border-radius: h(25px);
          &.focus {
            background-color: #b3f0eb;
            background-image: none;
            box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.5);
          }
          .text {
            font-weight: 500;
            font-size: toRem(32);
          }
        }
      }
      #branding {
        bottom: 0;
        #logo {
          background-image: url('../../assets/images/app-store-icon.png');
          width: h(61px);
          height: v(48px);
        }
        #tvStore {
          background: none;
          font-size: toRem(48);
          color: #ffffff;
          @extend %font-regular;
          white-space: nowrap;
        }
      }
    }

    .carousel .wrapper .slide .image {
      border-radius: h(8px);
    }
    .carousel .wrapper .focus .carouselShine {
      border-radius: h(8px);
    }

    .homeView {
      #points {
        .point {
          background-color: rgba(255, 255, 255, 0.35);
          &.selected {
            background-color: #ffffff;
          }
        }
      }
      .carouselTitle,
      .fixedTitle,
      .fixedExtended {
        color: #ffffff;
      }

      #homeContainer {
        .focus .app .bg .shining {
          border-radius: h(8px);
        }
        .app .bg {
          border-radius: h(8px);
        }
        &.carousel_1 {
          top: h(-445px);
        }
        &.carousel_5 {
          top: h(-2350px);
        }
        #carouselTvCategoriesWrapper {
          .title {
            font-weight: 500;
          }
          .focus .title {
            font-weight: 600;
          }
        }
        #categoriesCarousel {
          .app {
            .title {
              font-size: v(50px);
              font-weight: 500;
            }
          }
          .focus {
            .bg {
              background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
                linear-gradient(119deg, #395196, #5eb7c7 102%);
            }
            .title {
              font-size: v(60px);
              font-weight: 700;
            }
            .title.multiLine {
              font-size: v(50px);
              top: v(110px);
            }
          }
        }
      }
    }
    #search {
      #menuResults div,
      .carousel_recommended_movies .slide_title,
      #searchHistoryTitle,
      .userInput,
      .input .tip,
      .historyElem,
      .keyMatrix .key {
        color: #ffffff;
      }
      .key.focus {
        color: #1a1a1a;
      }
      .backKey {
        background: #b3f0eb;
      }
      .number {
        @extend %font-openSans;
      }
      .keyMatrix.alphabetic .key:last-child .backKey {
        width: v(70px);
      }
      .keyMatrix .space {
        //  background-image: url('../images/keyboard/space-light-turk.png');
      }
      .keyMatrix .focus > .space {
        // background-image: url('../images/keyboard/space-dark-turk.png');
      }
      .historyElem.focus {
        color: #1a1a1a;
        font-weight: 700;
      }
      #menuResults div.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
    }

    .settings {
      #menuSettings .settingsOption.active {
        background-color: rgba(21, 58, 100, 0.25);
      }
      #menuSettings .settingsOption.focus {
        background-color: #b3f0eb;
        background-image: none;
      }
      .switch .filling {
        background-color: #3ae1ab;
      }
      #portalFilter .fa-check {
        color: #140e2a;
      }
      .matrixSlide.focus .option > div {
        background-color: #b3f0eb;
      }
      .settingsOption.focus p,
      .matrixSlide.focus > div > div > p {
        color: #1a1a1a !important;
      }
      #languagesFilter p,
      #portalFilter .matrixSlide p,
      #tagLine,
      #settingsHeader,
      #menuSettings .settingsOption,
      #resetPortal .text {
        color: #ffffff;
      }
      #resetPortal .button {
        background-color: #588084;
        border-radius: h(25px);
        &.focus {
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
          background-color: #b3f0eb;
          background-image: none;
        }
      }
      #resetPortal .button p,
      #languagesFilter p {
        font-weight: 500;
        font-size: toRem(32);
      }
    }

    .appDetailsView {
      .button {
        background-color: rgba(21, 58, 100, 0.5);
        color: #ffffff;
        .text {
          font-weight: 500;
          font-size: toRem(32);
          top: h(-14px);
          left: v(-2px);
        }
        &.focus {
          color: #1a1a1a;
          background-color: #ffffff;
        }
      }
      #addToMyApps {
        // display: none;
      }
      .appLogo {
        border-radius: h(16px);
      }
      #waveBack {
        background-image: url('../../assets/images/backgrounds/background-turk-detail.png');
      }
      .appData,
      .carouselTitle {
        color: #1a1a1a;
      }
      .languages {
        top: v(108px);
      }
      .carouselTitle {
        font-size: toRem(40);
      }
    }
    .appCategory {
      .categoryTitle,
      .viewTitle {
        color: #ffffff;
      }
      .app {
        .title {
          color: #ffffff;
        }
        .bg img {
          border-radius: h(8px);
        }
      }
    }
    .moviesCategory {
      .title,
      .title_extended,
      .viewTitle {
        color: #ffffff;
      }
      .app {
        .title {
          color: #1a1a1a;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
    .videoCategory {
      .viewCategory,
      .title_extended,
      .viewTitle {
        color: #ffffff;
      }
      .app {
        .title {
          color: #ffffff;
        }
        .bg .image {
          border-radius: h(8px);
        }
      }
      .focus .app .bg .shining {
        border-radius: h(8px);
      }
    }
  }
}

.view {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffffff;
  font-size: toRem(40);
}
